const colorList = ['#0c9ee0', '#f98a05', '#5568f3', '#f25352', '#f252da', '#7abe0b', '#ff6a25', '#f8c004'];
const areaList = ['12,158,224', '249,138,5', '85,104,243', '242,83,82', '242,82,218', '122,190,11', '255,106,37', '248,192,4'];
const unitList = ['人', '人', '家', '家','家', '次', '元', '元'];
const options = ['实名', '注册', '新增商户', '登录商户', '首充商户', '消费商户数', '充值总金额', '消费总金额'];
const optionsDesc = [
  {
    label: '实名',
    desc: '统计时间内进行实名认证的用户数量'
  },
  {
    label: '注册',
    desc: '统计时间内注册的用户数+商户主账户数+商户子账户数'
  },
  {
    label: '新增商户',
    desc: '统计时间内通过推广链接注册的商户数量'
  },
  {
    label: '登录商户',
    desc: '统计时间内登录系统进行使用的商户数量'
  },
  {
    label: '首充商户',
    desc: '统计时间内进行首次充值的商户数量'
  },
  {
    label: '消费商户数',
    desc: '统计时间内商户进行消费的总次数'
  },
  {
    label: '充值总金额',
    desc: ' 统计时间内商户充值总金额(余额+星币)'
  },
  {
    label: '消费总金额',
    desc: '统计时间内商户消费总金额'
  }
]
const paramsName = {
  '实名': 'userRealNameNum',
  '注册': 'newsUser',
  '新增商户': 'newsMerchant',
  '登录商户': 'merchantLoginNum',
  '首充商户': 'firstRechargeNum',
  '消费商户数': 'consumptionNum',
  '充值总金额': 'rechargeAllMoney',
  '消费总金额': 'consumptionAllMoney',
}
export {
  colorList,
  areaList,
  unitList,
  options,
  optionsDesc,
  paramsName
}
