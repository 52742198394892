<template>
  <div class="control">
    <div class="control-head">
      <div class="control-card">
        <div class="card-box">
          <div class="showBox">
            <div class="grid-content bg-purple">
              <i class="el-icon-user purple-r"></i>
                <el-tooltip class="item" effect="dark" content="当天注册的用户数+商户主账户数+商户子账户数" placement="top">
                  <span class="badge badge-show">今日注册量</span>
                </el-tooltip>
              <h3>{{ cardData.registerToDayNum || 0 }}</h3>
              <div class="trend">
                较昨日
                <span
                  :class="Number(cardData.registerRatio) > 0 ? 'text-success' : 'text-danger'"
                >
                  <i
                    :class="Number(cardData.registerRatio) > 0 ? 'el-icon-top-right' : 'el-icon-bottom-right'"
                  ></i>
                  <span>{{ Math.abs(Number(cardData.registerRatio))}}%</span>
                </span>
                <span v-if="cardData.registerAllNum">
                  , 总计
                  <span :style="{color: '#5766da'}">{{ cardData.registerAllNum }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="showBox">
            <div class="grid-content bg-purple">
              <i class="el-icon-shopping-cart-2 purple-r"></i>
              <el-tooltip class="item" effect="dark" content="当天进行实名认证的用户数量" placement="top">
                <span class="badge badge-info">今日实名数</span>
              </el-tooltip>
              <h3>{{ cardData.rzToDayNum || 0}}</h3>
              <div class="trend">
                较昨日
                <span :class="Number(cardData.rzRatio) > 0 ? 'text-success' : 'text-danger'">
                  <i
                    :class="Number(cardData.rzRatio) > 0 ? 'el-icon-top-right' : 'el-icon-bottom-right'"
                  ></i>
                  <span>{{ Math.abs(Number(cardData.rzRatio))}}%</span>
                </span>
                <span v-if="cardData.rzAllNum">
                  , 总计
                  <span :style="{color: '#5766da'}">{{ cardData.rzAllNum }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="showBox">
            <div class="grid-content bg-purple">
              <i class="el-icon-s-finance purple-r"></i>
              <el-tooltip class="item" effect="dark" content='当天所有商户"余额充值+星币充值"的总金额' placement="top">
                <span class="badge badge-warning">充值金额</span>
              </el-tooltip>
              <h3>{{ cardData.todayMoney || 0 }}</h3>
              <div class="trend">
                较昨日
                <span :class="Number(cardData.moneyRatio) > 0 ? 'text-success' : 'text-danger'">
                  <i
                    :class="Number(cardData.moneyRatio) > 0 ? 'el-icon-top-right' : 'el-icon-bottom-right'"
                  ></i>
                  <span>{{ Math.abs(Number(cardData.moneyRatio))}}%</span>
                </span>
                <span v-if="cardData.allMoney">
                  , 总计
                  <span :style="{color: '#5766da'}">{{ cardData.allMoney }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="showBox">
            <div class="grid-content bg-purple">
              <i class="el-icon-folder-checked purple-r"></i>
              <el-tooltip class="item" effect="dark" content='当天通过推广链接注册的商户数量' placement="top">
                <span class="badge badge-show">新增商户</span>
              </el-tooltip>
              <h3>{{ cardData.todaySH || 0 }}</h3>
              <div class="trend">
                较昨日
                <span :class="Number(cardData.SHRatio) > 0 ? 'text-success' : 'text-danger'">
                  <i
                    :class="Number(cardData.SHRatio) > 0 ? 'el-icon-top-right' : 'el-icon-bottom-right'"
                  ></i>
                  <span>{{ Math.abs(Number(cardData.SHRatio))}}%</span>
                </span>
                <span v-if="cardData.AllSH">
                  , 总计
                  <span :style="{color: '#5766da'}">{{ cardData.AllSH }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="img-wrap">
          <img src="@/assets/img/dash.svg" alt />
        </div>
      </div>
      <!-- <div class="attributes">
        <el-tag
          size="small"
          v-for="(attr, index) in attributes"
          :key="index"
        >{{ attr.name }}: {{ attr.value }}</el-tag>
      </div>-->
    </div>
    <div class="echarts">
      <div class="selectHead">
        <div class="selectLeft">
          <el-checkbox-group
            v-model="checked"
            :max="2"
            @change="groupChange"
            >
            <el-tooltip v-for="(item, index) in optionsDesc" effect="dark" :content="item.desc" :key="index" placement="top">
              <el-checkbox :label="item.label">{{item.label}}</el-checkbox>
            </el-tooltip>
          </el-checkbox-group>
          <span class="tip">(最多可选两个参考量)</span>
          <el-button size="mini" type="primary" plain @click="checkedConfrime(time)">筛选</el-button>
        </div>
        <div class="selectRight">
          <el-radio-group v-model="time" size="small" @change="timeChange">
            <el-radio-button label="1">按天</el-radio-button>
            <el-radio-button label="2">按周</el-radio-button>
            <el-radio-button label="3">按月</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div ref="myChart" class="echarts-body"></div>
    </div>
  </div>
</template>

<script>
import service from './api';
import echarts from '@/lib/echarts';
import dayjs from 'dayjs';
import { colorList, areaList, unitList, options, optionsDesc, paramsName} from './constant'
import router from "../../router";
export default {
  data() {
    return {
      cardData: {},
      time: '1',
      checked:['实名', '注册'],
      optionsDesc: optionsDesc,
    };
  },
  computed: {
    userName() {
      let { account = '' } = this.$store.state.XINYOU_ADMIN_USER;
      return account;
    }
  },
  created(){
     let navs = JSON.parse(localStorage.getItem('xinyou_admin_user')).navs;
      this.$router.push({ path: navs[0].path || navs[0].children[0].path });
  },
  mounted() {
    let list = document.getElementsByClassName('el-checkbox__inner')
    for(let i = 0; i < list.length; i++){
      list[i].style.borderColor = colorList[i]
    };
    let params ={
      type: this.time,
      one: options.indexOf(this.checked[0])+1,
      two: options.indexOf(this.checked[1])+1
    }
    this.getData();
    this.getEchartData(params);
  },
  methods: {
    getData(){
      return service.getData().then(res => {
        this.cardData = res || {};
      })
    },
    getEchartData(params) {
      let colorArr = [];
      let areaArr = [];
      let unitArr = [];
      let nameArr = [];
      this.checked.map((item, index) => {
        let i = options.indexOf(item);
        colorArr[index] = colorList[i];
        areaArr[index] = areaList[i];
        unitArr[index] = unitList[i];
        nameArr[index] = options[i];
      })
      return service.getEchartData(params).then(res => {
        let charts = { date: [], dataList1: [], dataList2: [] };
        res.reverse().forEach(item => {
          if(this.time == '1'){
            charts.date.push(dayjs(item.date).format('MM-DD'));
          }else if(this.time == '2'){
            charts.date.push(item.weekNum + '周');
          }else{
            charts.date.push(item.monthNum + '月');
          }
          charts.dataList1.push(item[paramsName[this.checked[0]]]);
          charts.dataList2.push(item[paramsName[this.checked[1]]]);
        });
        charts.colorArr = colorArr;
        charts.areaArr = areaArr;
        charts.unitArr = unitArr;
        charts.nameArr = nameArr;
        charts.max1 = Math.ceil(Number(Math.max.apply(null, charts.dataList1) * 1.1));
        charts.max2 = Math.ceil(Number(Math.max.apply(null, charts.dataList2) * 1.1));
        this.drawLine(charts);
      });
    },
    //标签选择
    // groupChange(arr){
    //   console.log(arr)
    // },
    // 时间选择
    timeChange(time){
      this.checkedConfrime(time);
    },
    checkedConfrime(time){
      if(this.checked.length < 2){
        this.$alert('请选择两个参考量!', '提示', {
          type: 'warning',
        })
      }else{
        let params ={
          type: time,
          one: options.indexOf(this.checked[0])+1,
          two: options.indexOf(this.checked[1])+1
        }
        this.getEchartData(params)
      }
    },
    drawLine(charts) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs['myChart']);
      // 绘制图表
      myChart.setOption({
        title: {
          text: '曲线图',
        },
        color: charts.colorArr,
        tooltip: {
          trigger: 'axis',
          // axisPointer: {
          //   type: 'cross'
          // },
          backgroundColor: 'rgba(245, 245, 245, 0.8)',
          borderWidth: 1,
          borderColor: '#ccc',
          padding: 10,
          textStyle: {
            color: '#000'
          },
          // position: function (pos, params, el, elRect, size) {
          //   var obj = {top: 10};
          //   obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          //   return obj;
          // },
          // extraCssText: 'width: 170px'
        },
        // legend: { data: ['实名', '注册'], icon: 'circle', top: 'bottom' },
        grid:{
          top: '80',
          left: "8%",
          right: "8%"
        },
        xAxis: [
          {
            data: charts.date,
            boundaryGap: false,
            axisLine: { lineStyle: { color: '#999' } }
          }
        ],
        yAxis: [
          {
            type:'value',
            // minInterval: 1,
            max: charts.max1,
            axisLabel:{
              show:true,
              formatter: '{value}'+ charts.unitArr[0]
            },
            splitNumber: 8,
            // splitLine: { show: true, lineStyle:{ type:'solid'} },
            axisLine: {
              lineStyle:{
                color: charts.colorArr[0]
              }
            }
          },
          {
            type:'value',
            // minInterval: 1,
            max: charts.max2,
            axisLabel:{
              show:true,
              formatter: '{value}'+ charts.unitArr[1]
            },
            splitNumber: 8,
            splitLine: { show: true, lineStyle:{ type:'dashed'} },
            axisLine: {
              lineStyle:{
                color: charts.colorArr[1]
              }
            }
          }
        ],
        series: [
          {
            name: charts.nameArr[0],
            type: 'line',
            data: charts.dataList1,
            yAxisIndex: 0,
            // lineStyle: { width: 4 },
            itemStyle: { normal: { borderColor: charts.colorArr[0] } },
            // areaStyle: { color: charts.areaArr[0] },
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(' + charts.areaArr[0] + ', 0.7)'
              }, {
                offset: 1,
                color: 'rgba(' + charts.areaArr[0] + ', 0.2)'
              }])
            },
          },
          {
            name: charts.nameArr[1],
            type: 'line',
            data: charts.dataList2,
            yAxisIndex: 1,
            // lineStyle: { normal: { width: 4 } },
            itemStyle: { normal: { borderColor: charts.colorArr[1] } },
            // areaStyle: { color: charts.areaArr[1] },
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(' + charts.areaArr[1] + ', 0.7)'
              }, {
                offset: 1,
                color: 'rgba(' + charts.areaArr[1] + ', 0.2)'
              }])
            },
          }
        ]
      }, true);
    }
  }
};
</script>

<style lang="scss">
.control {
  text-align: left;
  &-head {
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 20px;
    .bg-purple {
      background: #ffffff;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
      border-radius: 3px;
      padding: 20px;
      position: relative;
      .purple-r {
        position: absolute;
        right: 20px;
        top: 30px;
        color: #e2e2f1;
        font-size: 24px;
        font-weight: 700;
      }
      .badge {
        padding: 4px 6px;
        font-size: 12px;
        color: #ffffff;
        border-radius: 3px;
        font-weight: 600;
        cursor: pointer;
      }
      .badge-show {
        background-color: #f93b7a;
      }
      .badge-info {
        background-color: #00bcd4;
      }
      .badge-warning {
        background-color: #fbb624;
      }
      h3 {
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0;
        color: #000000;
      }
      .trend {
        font-size: 13px;
        font-weight: 500;
        .text-success {
          color: #1ecab8 !important;
        }
        .text-danger {
          color: #f93b7a !important;
        }
      }
    }
    .bg-purple-light {
      background: #e5e9f2;
    }
    .grid-content {
      border-radius: 4px;
      min-height: 150px;
      box-sizing: border-box;
    }
    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }
  }

  &-card {
    width: 100%;
    display: flex;
    .card-box {
      min-width: 85%;
      display: flex;
      justify-content: space-between;
      .showBox {
        width: 22%;
      }
    }
    .img-wrap {
      flex: 1;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .echarts {
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 20px;
    .selectHead{
      overflow: hidden;
      margin-bottom: 30px;
      .selectLeft{
        float: left;
        padding-top:5px;
        .el-checkbox-group{
          display: inline-block;
        }
        .tip{
          color: #A0A0A0;
          margin: 0 15px;
          font-size: 14px;
        }
      }
      .selectRight{
        float:right;
      }
    }
    &-body {
      width: 100%;
      height: 450px;
    }
  }
  /*.el-checkbox{*/
  /*  &:nth-child(1){*/
  /*    .el-checkbox__inner{*/
  /*      border-color: #0c9ee0;*/
  /*    }*/
  /*  }*/
  /*}*/
  .el-checkbox{
    &:nth-child(1){
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #0c9ee0;
        }
      }
    }
    &:nth-child(2){
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #f98a05;
        }
      }
    }
    &:nth-child(3){
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #5568f3;
        }
      }
    }
    &:nth-child(4){
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #f25352;
        }
      }
    }
    &:nth-child(5){
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #f252da;
        }
      }
    }
    &:nth-child(6){
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #7abe0b;
        }
      }
    }
    &:nth-child(7){
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #ff6a25;
        }
      }
    }
    &:nth-child(8){
      .el-checkbox__input.is-checked{
        .el-checkbox__inner{
          background: #f8c004;
        }
      }
    }
  }
  .el-checkbox__input.is-checked+.el-checkbox__label{
    color:#606266;
  }
}
</style>
